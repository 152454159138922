import React from 'react';
import 'dayjs/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import { Button, DatePicker, Form, Input, Select, Dropdown } from 'antd';
const { RangePicker } = DatePicker;

const dateFormat = 'YYYY-MM-DD';


// 头部搜索
function Search(props) {
    const [form] = Form.useForm();
    // 导出为：
    const items = [
        // {
        //     key: '1',
        //     label: (
        //         <div onClick={() => exports('pdf')}>
        //             PDF
        //         </div>
        //     ),
        // },
        {
            key: '2',
            label: (
                <div onClick={() => exports('excel')}>
                    Excel
                </div>
            ),
        },
    ];
    const options = [
        {
            value: '成功',
            label: '成功',
        },
        {
            value: '失败',
            label: '失败',
        },
    ]
    // 导出
    const exports = (key) => {
        console.log(key)
        props.onexports(key)
    }
    // 提交成功
    const onFinish = (values) => {
        console.log('Success:', values);
        props.onChildSearch(values)

    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    // 下拉选择
    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };
    // 重置
    const onReset = () => {
        form.resetFields();
        props.onReset()
    }
    return (
        <div className='top_search' style={{paddingLeft:'20px'}}>
            <Form
                form={form}
                name="basic"
                layout='inline'
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label="日期"
                    name="startTime"
                    initialValue=''
                >
                    <RangePicker locale={locale} format={dateFormat} />
                </Form.Item>

                <Form.Item
                    label="接口名称"
                    name="apiName"
                    initialValue=''
                >
                    <Input placeholder="请输入" />
                </Form.Item>
                <Form.Item
                    label="请求状态"
                    name="status"
                    initialValue=''
                >

                    <Select
                        style={{
                            width: 160,
                        }}
                        allowClear
                        onChange={handleChange}
                        options={options}
                    />
                </Form.Item>

                <Form.Item
                    wrapperCol={{
                        offset: 8,
                        span: 16,
                    }}
                >

                    <Button htmlType="button" onClick={onReset}>
                        重置
                    </Button>
                </Form.Item>
                <Form.Item
                    wrapperCol={{
                        offset: 8,
                        span: 16,
                    }}
                >
                    <Button type="primary" htmlType="submit">
                        查询
                    </Button>
                </Form.Item>
            </Form>

            <Dropdown
                menu={{
                    items,
                }}
                placement="bottom"
                trigger='click'
                arrow
            >
                <Button type="primary" htmlType="submit" style={{ marginLeft: '20px' }} loading={props.loading}>
                    导出
                </Button>
            </Dropdown>
        </div>
    )
}
export default Search;