import App from '../App'
import Login from '../login'
import { BrowserRouter, Link, Routes, Route, Navigate } from 'react-router-dom';

function Main() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/*' element={<App />}></Route>
            </Routes>
        </ BrowserRouter>

    )
}
export default Main;