
import React, { useState, useRef, useEffect } from 'react';
import '../../assets/home.css'
import instance from '../../request/api';
import { Button, message, Table, Tag, Form, DatePicker } from 'antd';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom'
import excel from '../../export/libs/excel'
const { RangePicker } = DatePicker;

function Statistics() {

    // 设置table高度自动
    const tableVal = useRef(null)
    const [height, setHeihgt] = useState(100)
    // 表格加载
    const [loading, setLoading] = useState(false);
    // 表格数据
    let [tables, setTables] = useState([])
    // 分页
    let [page, setPage] = useState({
        page: 1,
        size: 20,
    })
    // 搜索数据
    let [search, setSearch] = useState({
        filter: {
            start: "",
            end: ""
        }
    })

    useEffect(() => {
        getRecordTableList()
    }, [search, page])

    const getRecordTableList = () => {
        // setLoading(true)
        instance.post('/api/v1/api_manage/push_statistics/pdd/page', { ...page, ...search })
            .then(data => {
                setLoading(false)
                setTables(data)
            }).catch(() => {
                setLoading(false)
            })
    }
    useEffect(() => {
        let val = tableVal.current.offsetHeight;
        setHeihgt(val - 120)
    }, [tables.list])

    // 头部提交
    const confirmSearch = (a, b) => {
        setSearch({
            filter:{
                start: a,
                end: b
            }
        })
    }

    // 页码变化时
    const onChangePage = (pageIndex, pageSize) => {
        setPage({
            page: pageIndex,
            size: pageSize
        })
    }
    // pagesize数量变化时
    const onShowSizeChangePage = (pageIndex, pageSize) => {
        setPage({
            page: pageIndex,
            size: pageSize,
        })
    }

    const columns = [
        {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: '100px'
        },
        {
            title: '日期',
            dataIndex: 'date',
            key: 'date',
            align: 'center',
        },
        {
            title: '推送次数',
            dataIndex: 'useCount',
            key: 'useCount',
            align: 'center',
        },
        {
            title: '计费总额（元）',
            dataIndex: 'amount',
            key: 'amount',
            align: 'center',
            render: (_, { amount }) => (
                <>
                    <p>￥{amount}</p>
                </>
            ),
        },
        {
            title: '计费平均单价（厘）',
            dataIndex: 'price',
            key: 'price',
            align: 'center',
        },

        {
            title: 'token',
            dataIndex: 'token',
            key: 'token',
            align: 'center',
            width: 200,
            render: (_, { token }) => (
                <>
                    <p style={{ width: '200px' }}>{token}</p>
                </>
            ),
        },
    ];
    return (
        <div className='boxFlex'>
            {/* 头部搜索 */}
            <Search confirmSearch={confirmSearch} dataSource={tables.total} search={search} pageConfig={page}></Search>
            <div className="tableList1" ref={tableVal}>
                <Table loading={loading} columns={columns} dataSource={tables.list} rowKey={record => record.id} scroll={{
                    scrollToFirstRowOnChange: false,
                    y: height
                }} pagination={{
                    position: ['bottomCenter'],
                    current: page.page,
                    defaultPageSize: page.size,
                    pageSizeOptions: [20, 50, 100, 200],
                    total: tables.total,
                    onChange: (page, size) => onChangePage(page, size),
                    onShowSizeChange: (current, size) => onShowSizeChangePage(current, size)
                }} />
            </div>
        </div>
    )
}

/**
 * 头部搜索
 * @returns 头部搜索
 */
function Search(props) {
    var nextId = '';
    var nextId1 = '';
    // 导出使用
    let page1 = {
        page: 1,
        size: 10000
    }
    let downExcel = [];
    const dateFormat = 'YYYY-MM-DD';
    let date = new Date();
    date.setDate(date.getDate() - 1)
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    // 搜索数据
    let [export1, setexport] = useState({
        start: '',
        end: '',
    })
    let [loading, setloading] = useState(false)
    const onFinish = (values) => {
        let start = '';
        let end = '';
        if (values.username) {
            start = `${values.username[0].$y}-${values.username[0].$M + 1}-${values.username[0].$D < 10 ? '0' + values.username[0].$D : values.username[0].$D}`;
            end = `${values.username[1].$y}-${values.username[1].$M + 1}-${values.username[1].$D < 10 ? '0' + values.username[1].$D : values.username[1].$D}`;
        }
        setexport({
            start: start,
            end: end
        })
        props.confirmSearch(start, end)
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    // 下载
    const clickDown = () => {
        downExcel = [];
        page1.page = 1;
        let total = props.dataSource;
        let num = Math.ceil(total / page1.size);
        setloading(true)
        handleExcelset(num);
    }
    const handleExcelset = (num) => {
        let page = page1.page;
        console.log('handleExcelset', downExcel);
        if (page <= num) {
            getTableData(num)
        } else {
            const params = {
                title: ['id', '日期', '推送次数', '计费总额（元）', '计费平均单价（厘）','token'],
                key: ['id', 'date', 'useCount', 'amount','price', 'token'],
                data: downExcel, // 数据源
                autoWidth: true, //autoWidth等于true，那么列的宽度会适应那一列最长的值
                filename: '主动推送报表导出'
            }
            excel.exportArrayToExcel(params);
            setloading(false)
        }
    }

    const getTableData = (num) => {
        instance.post('/api/v1/api_manage/push_statistics/pdd/page', { ...props.pageConfig, ...props.search })
            .then(res => {
                let a = downExcel;
                let data = a.concat(res.list);
                downExcel = data;
                page1.page += 1;
                nextId1 = res.nextId;
                handleExcelset(num)
            }).catch(() => {
                setTimeout(() => {
                    getTableData(num)
                }, 1500)
            })
    }
    return (
        <div style={{ background: '#fff', padding: "20px 20px 6px", display: "flex", justifyContent: 'space-between' }}>
            <Form
                layout="inline"
                name="basic"
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label="请选择日期"
                    name="username"
                >
                    <RangePicker format={dateFormat} />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        查询
                    </Button>
                </Form.Item>
            </Form>
            <Button type="primary" onClick={clickDown} loading={loading}>
                下载统计报表Excel
            </Button>
        </div>
    )
}

export default Statistics;