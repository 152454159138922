
import React, { useState, useRef, useEffect } from 'react';
import '../../assets/home.css'
import instance from '../../request/api';
import { Button, Table, Spin } from 'antd';

const columns = [
    {
        title: '',
        align:'center',
        dataIndex: 'idx',
        key: 'idx',
        width:'70px'
    },
    {
        title: '接口名称',
        align:'center',
        dataIndex: 'apiName',
        key: 'apiName',
    },
    {
        title: '中文名称',
        align:'center',
        dataIndex: 'apiTitle',
        key: 'apiTitle'
    },
    {
        title: '变动前价格（里）',
        align:'center',
        dataIndex: 'beforePrice',
        key: 'beforePrice',
    },
    {
        title: '变动后价格（里）',
        align:'center',
        dataIndex: 'afterPrice',
        key: 'afterPrice',
    },
    {
        title: '变动时间',
        align:'center',
        dataIndex: 'logTime',
        key: 'logTime',
    },

];

const View = () => {
    // 设置table高度自动
    const tableVal = useRef(null)
    const [loading, setloading] = useState(false)
    const [tables, setTables] = useState([])
    const [page, setPage] = useState(1)
    const [size, setSize] = useState(20)
    const [total, setTotal] = useState(0)
    const [height, setHeihgt] = useState(0)
    useEffect(() => {
        getusers()
        let val = tableVal.current.offsetHeight;
        setHeihgt(val - 120)
    }, [page,size])
    const getusers = () => {
        setloading(true)
        instance.post('/api/v1/api_manage/price_adjust_log',{page,size})
            .then(data => {
                setloading(false)
                let val = [];
                if(data.list.length > 0){
                    for(let i in data.list){
                        let idx = parseInt(i)+1;
                        val.push({...data.list[i],idx})
                    }
                }
                setTables(val)
                setTotal(data.total)
            }).catch(() => {
                setloading(false)
            })
    }

    // 页码变化时
    const onChangePage = (page) => {
        console.log(page)
        setPage(page)
    }
    // pagesize数量变化时
    const onShowSizeChangePage = (curr, pageSize) => {
        console.log(pageSize)
        setSize(pageSize)
    }
    return (
        <div className='boxFlex' style={{background:'#fff'}}>
            <div className='tableList1' ref={tableVal} style={{background: '#fff' }}>
                <Spin spinning={loading}>
                    <Table columns={columns} dataSource={tables} rowKey={record => record.apiName} scroll={{
                        scrollToFirstRowOnChange: true,
                        y: height
                    }} pagination={{
                        position: ['bottomCenter'],
                        current: page,
                        defaultPageSize: size,
                        pageSizeOptions: [20, 50, 100, 200],
                        total: total,
                        onChange: (page) => onChangePage(page),
                        onShowSizeChange: (current, size) => onShowSizeChangePage(current, size)
                    }} />
                </Spin>
            </div>
        </div>
    )
}
export default View;