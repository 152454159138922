import { LaptopOutlined, TableOutlined, ApartmentOutlined, SnippetsOutlined, BarChartOutlined, AlertOutlined,SlidersOutlined } from '@ant-design/icons';
import { Layout, Menu, theme } from 'antd';
import { React, useState, useEffect } from 'react';
import logo from './assets/images/logo.png'
import Home from './pages/Home'
import User from './pages/User'
import Record from './pages/Record'
import Subuser from './pages/Sub_user'
import Apiprices from './pages/Api_prices'
import Batchuser from './pages/Sub_user/batch_user'
import Statistics from './pages/Statistics/index'
import JdStatistics from './pages/Statistics/jd'
import ApiStatistics from './pages/Statistics/api'
import UserStatistics from './pages/Statistics/user_price_change'
import Active from './pages/Statistics/active'
import Yuer from './pages/Yu_er/index'
import PriceRecord from './pages/PriceRecord/index'
import userIcon from './assets/images/user.png'
import { Link, Routes, Route } from 'react-router-dom';
const { Header, Content, Sider } = Layout;

function App() {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const [userinfo, setUserinfo] = useState('')
  const [keys, setKeys] = useState(sessionStorage.getItem('key') || '/');
  // 退出
  const handleOut = () => {
    window.localStorage.removeItem('token');
    window.location.href = 'https://sso.datamonitor.shop/connect/logout?post_logout_redirect_uri=http%3A%2F%2Fapi.admin.datamonitor.shop%2Fsignout-callback-oidc&client_id=datamonitor.api.admin'
  }
  useEffect(() => {
    let originalSetItem = localStorage.setItem;
    localStorage.setItem = function (key, newValue) {
      let event = new Event("setItemEvent");
      event.key = key;
      event.newValue = newValue;
      window.dispatchEvent(event);
      originalSetItem.apply(this, arguments);
    }
  }, [])
  window.addEventListener("setItemEvent", function (e) {
    if (e.key === 'userInfo') {
      let oldValue = localStorage.getItem('userInfo');
      if (oldValue !== e.newValue) {
        setUserinfo(JSON.parse(e.newValue).name)
      } else {
        setUserinfo(JSON.parse(e.newValue).name)
      }
    }
  })
  const clickSelect = (item) => {
    window.sessionStorage.setItem('key', item.key)
  }
  return (
    <>

      <Layout style={{ minHeight: '100vh' }}>
        <Header
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <div className="logo">
            <img src={logo} />
          </div>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}>
            <a href='https://apifox.com/apidoc/shared-d71735a2-d7ff-416f-8b9d-35637fba5c16' target='_blark'
              style={{ marginRight: '20px', color: '#2d8cff', fontSize: '14px' }}>接口文档</a>
            <Link to='/user' style={{ color: '#fff' }}>
              <div className='headerFlex'>
                <img src={userIcon} className='header_user' />
                {userinfo}
              </div>
            </Link>
            <span className='out' onClick={handleOut}>退出</span>
          </div>
        </Header>
        <Layout>
          <Sider
            width={200}
            style={{
              background: colorBgContainer,
            }}
          >
            <Menu
              mode="inline"
              defaultSelectedKeys={[keys]}
              onSelect={clickSelect}
              style={{
                height: '100%',
                borderRight: 0,
                paddingTop: '20px',
                boxSizing: 'border-box'
              }}
            >
              <Menu.Item key="/" icon={<TableOutlined />}>
                <Link to='/'>消费统计</Link>
              </Menu.Item>
              <Menu.Item key="/record" icon={<LaptopOutlined />}>
                <Link to='/record'>充值记录</Link>
              </Menu.Item>
              <Menu.Item key="/sub_user" icon={<ApartmentOutlined />}>
                <Link to='/sub_user'>下级管理</Link>
              </Menu.Item>
              <Menu.Item key="/api_prices" icon={<SnippetsOutlined />}>
                <Link to='/api_prices'>资费明细</Link>
              </Menu.Item>
              <Menu.Item key="/balance" icon={<AlertOutlined />}>
                <Link to='/balance'>余额预警</Link>
              </Menu.Item>
              <Menu.Item key="/price_record" icon={<SlidersOutlined />}>
                <Link to='/price_record'>价格变动记录</Link>
              </Menu.Item>
              <Menu.SubMenu key='statis' title='统计报表' icon={<BarChartOutlined />}>
                <Menu.Item key="/statistics">
                  <Link to='/statistics'>拼多多计费报表</Link>
                </Menu.Item>
                <Menu.Item key="/jd">
                  <Link to='/jd'>京东计费报表</Link>
                </Menu.Item>
                <Menu.Item key="/api_statistics">
                  <Link to='/api_statistics'>接口计费报表</Link>
                </Menu.Item>
                <Menu.Item key="/user_tatistics" >
                  <Link to='/user_tatistics'>余额变动报表</Link>
                </Menu.Item>
                <Menu.Item key="/active">
                  <Link to='/active'>主动推送报表</Link>
                </Menu.Item>
              </Menu.SubMenu>
            </Menu>
          </Sider>
          <Layout
            style={{
              padding: '24px',
            }}
          >
            <Content
              style={{
                padding: 0,
                margin: 0,
                minHeight: 280,
              }}
            >
              <Routes>
                <Route path='/' element={<Home />}></Route>
                <Route path='/user' element={<User />}></Route>
                <Route path='/record' element={<Record />}></Route>
                <Route path='/sub_user' element={<Subuser />}></Route>
                <Route path='/sub_user/batch' element={<Batchuser />}></Route>
                <Route path='/api_prices' element={<Apiprices />}></Route>
                <Route path='/statistics' element={<Statistics />}></Route>
                <Route path='/jd' element={<JdStatistics />}></Route>
                <Route path='/api_statistics' element={<ApiStatistics />}></Route>
                <Route path='/user_tatistics' element={<UserStatistics />}></Route>
                <Route path='/active' element={<Active />}></Route>
                <Route path='/balance' element={<Yuer />}></Route>
                <Route path='/price_record' element={<PriceRecord />}></Route>
              </Routes>
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </>
  );
}

export default App;
