import '../../assets/home.css'
import { Alert, Space } from 'antd';
import { useEffect, useState } from 'react';
import instance from '../../request/api';
import Search from '../../components/Home/Search'
import Statistics from '../../components/Home/Statistics'
import Statistics1 from '../../components/Home/Statistics1'
import Tablelist from '../../components/Home/TableList'
import excel from '../../export/libs/excel'

// 格式化日期
const setTime = (time) => {
    if (!time) return '';
    let y = time.$y;
    let m = time.$M + 1 < 10 ? '0' + (time.$M + 1) : time.$M + 1;
    let d = time.$D < 10 ? '0' + time.$D : time.$D;
    return `${y}-${m}-${d}`
}

function Homeindex() {
    //初始化
    useEffect(() => {

        // 获取url地址
        let url = window.location.href;
        let idToken = url.split('id_token=');
        if (idToken.length > 1) {
            let a = JSON.stringify(idToken[1]);
            let token = a.split('&state')
            let n = token[0].substr(1);
            localStorage.setItem('token', n)
        }
        // 获取用户信息
        get_userinfo()
    }, [])
    const get_userinfo = () => {
        instance.get('/api/v1/api_manage/users')
            .then(data => {
                localStorage.setItem('userInfo', JSON.stringify(data))
            })
    }

    const [pageState, setPageState] = useState(false);
    // 消费数据总览
    const [statics, setStatis] = useState({
        day: {
            days7Amount: "0",
            yesterdayAmount: "0",
            days7Use: "0",
            yesterdayUse: "0",
            balance: "0"
        }
    })
    // 表格加载
    const [loading, setLoading] = useState(false);
    const [loading1, setLoading1] = useState(false);
    // 页码
    const [page, setPage] = useState({
        page: 1,
        size: 20
    })
    var nextId = '';
    var nextId1 = '';
    // 导出使用
    let page1 = {
        page: 1,
        size: 10000
    }
    let downExcel = [];
    // 搜索内容
    const [search, setSearch] = useState({
        startTime: '',
        endTime: '',
        apiName: '',
        status: ''
    })
    // table数据
    const [tables, setTables] = useState([])

    // 头部搜索
    const handleChildSearch = (val) => {
        if (!val) return;
        let obj = { ...search };
        obj.startTime = val.startTime ? setTime(val.startTime[0]) : '';
        obj.endTime = val.startTime ? setTime(val.startTime[1]) : '';
        obj.apiName = val.apiName;
        obj.status = val.status;
        setLoading(true)
        setPageState(true);
        setSearch(obj)
    }
    useEffect(() => {
        getStatisics()
        getPages()
    }, [page, search])

    // 导出
    const onChildexports = (key) => {
        downExcel = [];
        page1.page = 1;
        let total = tables.total;
        let num = Math.ceil(total / page1.size);
        setLoading1(true)
        handleExcelset(num);
    }
    const handleExcelset = (num) => {
        let page = page1.page;
        console.log('handleExcelset',downExcel);
        if (page <= num) {
            getTableData(num)
        } else {
            const params = {
                title: ['消费编号', 'Token', '接口名称', 'IP地址', '请求状态', '使用时间', '计费（厘）', '余额（元）'],
                key: ['id', 'token', 'apiName', 'ipAddress', 'status', 'time', 'charging', 'balance'],
                data: downExcel, // 数据源
                autoWidth: true, //autoWidth等于true，那么列的宽度会适应那一列最长的值
                filename: '消费统计导出'
            }
            excel.exportArrayToExcel(params);
            setLoading1(false)
        }
    }

    const getTableData = (num) => {
        console.log('num:',num);
        console.log('page1.page:',page1.page);
        instance.post('/api/v1/api_manage/consume_statistics/pages', {
            page: {
                page: page1.page,
                size: page1.size
            },
            search: {
                ...search,
                nextId:nextId1
            }
        })
            .then(res => {
                let a = downExcel;
                let data = a.concat(res.list);
                downExcel = data;
                page1.page += 1;
                nextId1 = res.nextId;
                handleExcelset(num)
            }).catch(() => {
                setTimeout(()=>{
                    getTableData(num)
                },1500)
            })
    }

    // table组件接收子组件的参数 page页码
    const handleChildClick = (msg) => {
        let data = { ...page };
        data.page = msg;
        setPage(data)
    }
    // table组件接收子组件的参数 size数量
    const handleChildClickSize = (msg) => {
        let data = { ...page };
        data.size = msg;
        setPage(data)
    }
    const getStatisics = () => {
        if (pageState) {
            instance.post('/api/v1/api_manage/consume_statistics', { search })
                .then(data => setStatis(data))
                .catch(err => {
                    console.log('err', err)
                })
        } else {
            instance.get('/api/v1/api_manage/consume_statistics')
                .then(data => {
                    let val = {};
                    val.day = data;
                    setStatis(val)
                })
                .catch(err => {
                    console.log('err', err)
                })
        }

    }

    // 手动获取分页数据信息
    const getPages = () => {
        console.log(nextId);
        instance.post('/api/v1/api_manage/consume_statistics/pages', {
            page,
            search: {
                ...search,
                nextId:nextId
            }
        })
            .then(data => {
                setLoading(false)
                setTables(data)
                nextId = data.nextId;
                
            }).catch(() => {
                setLoading(false)
            })
    }
    // 搜索重置
    const handleReset = () => {
        setPage({ page: 1, size: 20 })
        setSearch({ startTime: '', endTime: '', status: '', apiName: '' })
    }

    // 导出文件处理
    const handle = (data, type) => {
        if (!data) return;
        let t = type == 'pdf' ? 'pdf' : 'vnd.ms-excel';
        let url = window.URL.createObjectURL(new Blob([data], { type: `application/${t}` }))
        const a = document.createElement('a')
        a.style.display = 'none'
        a.download = setTimes();
        a.href = url
        a.click()
        if (document.body.contains(a)) {
            document.body.removeChild(a)
        }
    }
    const setTimes = () => {
        let myData = new Date();
        let year = myData.getFullYear();
        let mon = myData.getMonth() + 1;
        let date = myData.getDate();
        let hours = myData.getHours();
        let min = myData.getMinutes();
        let seconds = myData.getSeconds();
        let now = `${year}${mon}${date}${hours}${min}${seconds}`
        return now
    }
    return (
        <div className='boxFlex'>
            <Space
                direction="vertical"
                style={{
                    width: '100%',
                }}
            >
                <Alert
                    message="该页面下仅保存近60天数据"
                    banner
                    closable
                />
            </Space>
            {/* 头部搜索 */}
            <Search onChildSearch={handleChildSearch} loading={loading1} onReset={handleReset} onexports={onChildexports} />
            {/* 消费数据总览 */}
            {
                pageState ? <Statistics val={statics} /> : <Statistics1 val={statics} />
            }

            {/* 表格 */}
            <Tablelist tables={tables} loading={loading} onChildClick={handleChildClick} onChildClickSize={handleChildClickSize} />

        </div>
    )
}
export default Homeindex;